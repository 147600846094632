import { useLocation, useParams } from "react-router-dom";
import type { DoctorDetailResponse } from "../../models/consultationsTypes";
import {
    FormDataType,
    IPaymentData,
    IPaymentInfo,
    PaymentsTypesEnum
} from "../../models/paymentTypes";
import {
    getConsultationPaymentData,
    getPrescriptionPaymentData,
    getSubscriptionPaymentData
} from "../../store/actions/paymentActions";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PaymentService from "../../services/PaymentService";
import { showNotification } from "../../helpers/showNotification";
import { Loader } from "../../components/common/Loader";
import { CardContainer, PaymentPageContainer } from "./styles";
import { PaymentInfo } from "../../components/payment/info/PaymentInfo";
import { Card } from "../../components/payment/card/Card";
import { Form } from "../../components/payment/form/Form";
import { useAppSelector } from "../../hooks/redux";

export const defaultPaymentInfo = {
    ip: "",
    price: 0,
    doctor: {} as DoctorDetailResponse,
    description: "",
    callbackUrl: ""
};

const actions = {
    [PaymentsTypesEnum.CONSULTATION]: getConsultationPaymentData,
    [PaymentsTypesEnum.PRESCRIPTION]: getPrescriptionPaymentData,
    [PaymentsTypesEnum.SEASON]: getSubscriptionPaymentData
};

function MobileVersion() {
    const { user } = useAppSelector((state) => state.userReducer);
    const { doctorId, orderId } = useParams();
    const { search } = useLocation();
    const [{ ip, price, description, callbackUrl }, setPaymentData] =
        useState<IPaymentInfo>(defaultPaymentInfo);
    const [loading, setLoading] = useState<boolean>(true);
    const [formData, setFormData] = useState<FormDataType>({});
    const [cardFlipped, setCardFlipped] = useState<boolean>(false);
    const { t } = useTranslation();
    const host = window.location.origin;
    const token = new URLSearchParams(search).get("token");
    const type = new URLSearchParams(search).get("type");

    const getPaymentData = async () => {
        if (!type) {
            showNotification("Order type is missing", "error");
            window.ReactNativeWebView.postMessage("payment_error");
            return;
        }

        if (!token) {
            showNotification("Token is missing", "error");
            window.ReactNativeWebView.postMessage("payment_error");
            return;
        }

        try {
            const data = await actions[type as PaymentsTypesEnum](host, orderId, doctorId);
            setPaymentData(data);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getPaymentData();
    }, [type]);

    const handleSubmitForm = async () => {
        if (
            !orderId ||
            !formData.card_number ||
            !formData.expires ||
            !formData.card_cvv2 ||
            !formData.payer_phone ||
            !formData.payer_email
        )
            return;
        try {
            setLoading(true);
            const payload: IPaymentData = {
                action: "SALE",
                order_id: orderId,
                order_amount: Number(`${price}`).toFixed(2),
                order_currency: "UAH",
                order_description: description,
                payer_ip: ip,
                term_url_3ds: callbackUrl,
                card_number: parseInt(formData.card_number.replace(/\s+/g, ""), 10),
                card_exp_month: formData.expires.split("/")[0],
                card_exp_year: +formData.expires.split("/")[1],
                card_cvv2: +formData.card_cvv2,
                payer_email: formData.payer_email,
                payer_phone: +formData.payer_phone,
                req_token: "Y",
                recurring_init: "Y",
                doctor_id: doctorId,
                type: type as PaymentsTypesEnum
            };

            const { data } = await PaymentService.postPayment(payload);

            if (data.status === "OK" && data.data.redirect_url) {
                window.open(data.data.redirect_url, "_blank");
            } else {
                const platonResponse = data.data.error_message;
                const message = platonResponse
                    ? `Payment service provider error:${platonResponse}`
                    : t("Payment processing error, please try again later");
                showNotification(message, "error");
                window.ReactNativeWebView.postMessage("payment_error");
            }
        } catch (error) {
            console.log(error);
            showNotification(t("Payment processing error, please try again later"), "error");
            window.ReactNativeWebView.postMessage("payment_error");
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <Loader width="100%" height="100vh" />;
    }

    return (
        <PaymentPageContainer>
            <PaymentInfo orderId={orderId} price={`${price}`} description={description} />
            <CardContainer>
                <Card formData={formData} cardFlipped={cardFlipped} />
                <Form
                    email={user?.email ?? ""}
                    phone={user?.phone ?? ""}
                    price={Number(`${price}`).toFixed(2)}
                    setCardFlipped={setCardFlipped}
                    setFormData={setFormData}
                    handleSubmitForm={handleSubmitForm}
                />
            </CardContainer>
        </PaymentPageContainer>
    );
}

export default MobileVersion;

declare global {
    interface Window {
        ReactNativeWebView: {
            postMessage: (message: string) => void;
        };
    }
}
